import React, { useState } from 'react';
import { TextField, Button, Card, Typography, Paper, Container, Box } from '@mui/material';
import ely_survey from '../../assets/ely_survey.png'
import { db, firebase } from '../../firebase';
import Swal from "sweetalert2";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { Tune } from '@mui/icons-material';

const CedulaPage = ({ setShowLandingPage, setUid, setNombres, setEmail }) => {
    const [number, setNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Estado para controlar si se está cargando

    const handleChange = (event) => {
        setNumber(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw2 = JSON.stringify({
                mensaje: 'Mensaje',
                asunto: 'Asunto',
                imagen: 'https://firebasestorage.googleapis.com/v0/b/sistema-gestion-intercommerce.appspot.com/o/instalaciones%2F%20-%20VALAREZO%20VALAREZO%20ATAHUALPA%20-%20MELISSA%20NAOMI%20AVILES%20SEVILLANO%20%2Ffoto_potencia_caja?alt=media&token=e9e0c02f-e347-4ad9-92fa-4dcb55ca6a3a',
                cliente: 'Nombre',
                correo: 'Correo'
                // correo: 'rainiero.cedillo@intercommerce.com.ec'
            })

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw2
            };

            const response = await fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api24EnLinea/enviarCorreo", requestOptions);

            if (!response.ok) {
                throw new Error('Failed to fetch');
            }

            console.log('Correo enviado exitosamente');
            // Aquí puedes agregar tu lógica adicional después de enviar el correo
        } catch (error) {
            console.error('Error al enviar el correo:', error.message);
            // Maneja el error de la forma que desees, como mostrar un mensaje al usuario
        }
    };


    const consultCedulaUser = async () => {
        setIsLoading(true); // Establecer isLoading a true cuando se inicia la consulta
        try {
            const consult_user = firebase.functions().httpsCallable("consult_user");

            const data = {
                cedula: number,
            }

            const response = await consult_user(data);

            // Verificamos si la respuesta contiene un mensaje
            if (response.data) {
                // Si la respuesta tiene un mensaje, lo mostramos en la consola
                console.log(response.data.message);
                if (response.data.message === 'Se encontró la cedula') {
                    Swal.fire({
                        icon: "success",
                        title: "Ya ha realizado la encuesta con exito",
                    });
                } else {
                    consultUser()
                }
                // Aquí puedes agregar lógica adicional dependiendo de la respuesta recibida
            } else {
                console.log("No se recibió ningún mensaje en la respuesta.");
            }


        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);
        } finally {
            setIsLoading(false); // Establecer isLoading a false después de que se completa la consulta
        }
        /* try {
          const response = await fetch('https://us-central1-yiga5-24enlinea.cloudfunctions.net/relational_survey', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ question1 }),
          });
    
          const data = await response.text();
          console.log('data', data)
          setResponseMessage(data);
        } catch (error) {
          console.error('Error:', error);
          setResponseMessage('Error al procesar la solicitud');
        } */
    };

    const consultUser = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({
                "token": "VzhoNEE2ZWlEdFFDUTJMaUJOS21Edz09",
                "cedula": number
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch("https://us-central1-yiga5-24enlinea.cloudfunctions.net/api/GetClientsDetails", requestOptions)
                .then(response => response.text())
                .then(result => {
                    const dataJSON = JSON.parse(result)
                    console.log(dataJSON, 'resultado')
                    console.log(dataJSON.mensaje, 'mensaje')
                    if (dataJSON.estado === 'error') {
                        Swal.fire({
                            icon: "error",
                            title: "No existe cliente con la cedula digitada",
                        });
                    } else if (dataJSON.mensaje === 'No existe el cliente con el filtro indicado.') {
                        Swal.fire({
                            icon: "error",
                            title: "No existe cliente con la cedula digitada",
                        });
                    } else {
                        //Obtenemos los correos de ese cliente
                        let retiradoEncontrado = false; // Bandera para indicar si se encontró al menos un cliente retirado
                        // Iterar sobre cada cliente en el arreglo de datos
                        dataJSON.datos.forEach(cliente => {
                            // Verificar si el estado del cliente es 'RETIRADO'
                            if (cliente.estado === 'RETIRADO') {
                                retiradoEncontrado = true;
                            } else {
                                setUid(cliente.cedula)
                                setNombres(cliente.nombre)
                                setEmail(cliente.correo)
                            }
                        });
                        // Determinar el mensaje a mostrar
                        if ((retiradoEncontrado && dataJSON.datos.length > 1) || (dataJSON.datos.length === 1 && dataJSON.datos[0].estado === 'ACTIVO')) {
                            console.log('Todo correcto');
                            setShowLandingPage(true);

                        } else if (retiradoEncontrado) {
                            console.log('Retirado');
                            Swal.fire({
                                icon: "error",
                                title: "El cliente se encuentra retirado",
                            });
                        }
                    }
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <Container maxWidth='lg' style={{ backgroundColor: 'transparent' }}>
            <Paper style={{ textAlign: 'center', backgroundColor: 'transparent' }} elevation={0}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <img src={ely_survey} alt="soei" width="100" />
                    <Box pt={2}>
                        <Typography variant="h5" style={{ color: 'purple' }}>YIGA 5 quiere saber tu opinión sobre la instalación del servicio.</Typography>
                    </Box>
                    <Box py={2}>
                        <Typography variant="subtitle2">Participa en esta encuesta rápida que no tomará más de 5 minutos de tu tiempo.</Typography>
                    </Box>
                    <Typography variant="subtitle2">Digite su numero de cedula para comenzar la encuesta</Typography>

                    <Box py={2}>
                        <TextField
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }}
                            variant="outlined"
                            value={number}
                            onChange={handleChange}
                            inputProps={{ maxLength: 13, minLength: 10 }}
                            size='small'
                        />
                    </Box>
                    <LoadingButton
                        loading={isLoading}
                        endIcon={isLoading === true ? <SendIcon /> : ''}
                        loadingPosition="end"
                        disabled={number.length < 10}
                        variant="contained"
                        color="secondary"
                        onClick={consultCedulaUser}
                        size='small'>
                        ¡Comenzar encuesta!
                    </LoadingButton>
                </div>
            </Paper>
        </Container>
    );
};

export default CedulaPage;
