import React from 'react';
import './styles.css';
import AppRouter from './AppRouter'; // Importa el componente Router que acabamos de crear

function App() {
  return (
    <div className="App">
      <AppRouter /> {/* Usa el componente Router aquí */}
    </div>
  );
}

export default App;
