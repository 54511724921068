import React, { useState } from 'react';
import { TextField, Typography, Box, Container, Paper } from '@mui/material';

const SelectionInOrderQuestion = ({ question, handleCheckboxValueChange, options }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const updateRanking = (optionIndex) => {
        const selectedIndex = selectedOptions.findIndex((item) => item.opcion === optionIndex + 1);
        let newSelectedOptions = [...selectedOptions];

        if (selectedIndex === -1) {
            // La opción no está seleccionada, agregarla con el ranking correspondiente
            const ranking = selectedOptions.length + 1;
            const newOption = { opcion: optionIndex + 1, label: options[optionIndex], ranking: ranking };
            newSelectedOptions.push(newOption);
        } else if (selectedIndex === selectedOptions.length - 1) {
            // La opción es la última seleccionada, desmarcarla
            newSelectedOptions.pop();
        } else {
            // La opción no es la última seleccionada, no se puede desmarcar
            return;
        }

        setSelectedOptions(newSelectedOptions);
        handleCheckboxValueChange(newSelectedOptions); // Actualizar estado del padre
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                    {question}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            style={{ marginBottom: '8px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                            onClick={() => updateRanking(index)}
                        >
                            <TextField
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                    style: { width: '44px', marginLeft: 'auto', fontSize: '14px' } // Mover el input hacia la izquierda
                                }}
                                value={selectedOptions.findIndex((item) => item.opcion === index + 1) !== -1 ? selectedOptions.findIndex((item) => item.opcion === index + 1) + 1 : ''}
                            />
                            <Box ml={1}>
                                <span style={{ marginRight: '8px', fontSize: '14px' }}>{option}</span>
                            </Box>
                        </div>
                    ))}
                </div>
            </Paper>
        </Container>
    );
};

export default SelectionInOrderQuestion;
