import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// Credenciales para conectarse a firebase
const firebaseConfig = {
    apiKey: "AIzaSyDM1-2Qk4O3BSCb4uaHb25uY9g8VnMW2Uo",
    authDomain: "yiga5-24enlinea.firebaseapp.com",
    projectId: "yiga5-24enlinea",
    storageBucket: "yiga5-24enlinea.appspot.com",
    messagingSenderId: "151755992585",
    appId: "1:151755992585:web:7573ea726e4531252d92d8",
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth()
const storage = firebase.storage()
const db = firebase.firestore()
const functions = firebase.functions()
export { db, auth, firebase, functions, storage } 