import React from 'react';
import {
    Typography, TextField, Container, Paper
} from "@mui/material";

const TextFieldQuestion = ({ question, answer, handleTextFieldChange }) => {
    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '16px' }}>
                    {question}
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    value={answer}
                    onChange={(event) => handleTextFieldChange(event.target.value)}
                    variant="outlined"
                    placeholder="Escribe tu respuesta aquí"
                />
            </Paper>
        </Container>
    );
};

export default TextFieldQuestion;
