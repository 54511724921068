import React from 'react';
import { Typography, Paper, Container, Radio, FormControlLabel, Box } from '@mui/material';

const HorizontalOptionsQuestion = ({ 
    question, 
    selectedValue, 
    handleRadioValueChange, 
    range = 10, 
    minLabel = 'Nada satisfecho', 
    maxLabel = 'Muy satisfecho' 
}) => {
    
    const buildRadioOption = (value) => (
        <FormControlLabel
            key={value}
            value={value.toString()}
            control={
                <Radio
                    size="small"
                    checked={selectedValue === value}
                    onChange={() => handleRadioValueChange(value)}
                    sx={{ 
                        marginRight: { xs: '1px', sm: '1px' }, // Ajuste de margen en pantallas pequeñas
                        padding: 0,
                        '&.Mui-checked': { color: 'purple' },
                    }}
                />
            }
            label={
                <Typography 
                    sx={{ fontSize: { xs: '12px', sm: '14px' } }} // Ajuste de tamaño de fuente en pantallas pequeñas
                >
                    {value}
                </Typography>
            }
            sx={{ marginRight: { xs: '13px', sm: '20px' } }}
        />
    );

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {question}
                </Typography>
                <Box display="flex" justifyContent="center">
                    {[...Array(range + 1).keys()].map(value => buildRadioOption(value))}
                </Box>
                <Box display="flex" justifyContent="space-between" fontSize="14px" marginTop="4px">
                    <Typography>{minLabel}</Typography>
                    <Typography>{maxLabel}</Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default HorizontalOptionsQuestion;
