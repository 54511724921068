import React from 'react';
import {
    Typography, Radio, FormControlLabel, Container, Paper
} from "@mui/material";

const SingleOptionQuestion = ({ question, options, selectedOption, handleRadioValueChange }) => {

    const buildRadioOption = (value, label) => {
        return (
            <div key={value} style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                <FormControlLabel
                    value={value}
                    checked={selectedOption === label}
                    onChange={() => handleRadioValueChange(label)}
                    control={<Radio sx={{ '&.Mui-checked': { color: 'purple' } }} />} // Cambia el color del Radio cuando está seleccionado
                    label={<Typography style={{ fontSize: '14px' }}>{label}</Typography>}
                />
            </div>
        );
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {question}
                </Typography>
                {options.map(option => buildRadioOption(option.value, option.label))}
            </Paper>
        </Container>
    );
};

export default SingleOptionQuestion;
