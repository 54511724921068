import React from 'react';
import { Typography, Paper, Container, Radio, Grid } from '@mui/material';

const Survey18Widget = ({ surveyData, setSurveyData }) => {
    // Lista de subpreguntas
    const subQuestions = [
        'En APP Yiga Experience:',
        'En Chatbot Ely:',
        'Por canal telefónico:',
        'Por visita técnica:',
        'En Centro de Experiencia:',
        'En Tótem de autogestión:',
        'En Yiga salud:',
        'En Yigacoins:',
        'En Yiga defender:',
        'En Omni Mesh:',
        'En Mini Ups:',
        'En Zapping:'
    ];

    const handleOptionChange = (subQuestion, value) => {
        const updatedFifteenthCuestion = { ...surveyData.fifteenthCuestion };
        updatedFifteenthCuestion[subQuestion] = value;

        setSurveyData(prevState => ({
            ...prevState,
            fifteenthCuestion: updatedFifteenthCuestion
        }));

        const optionIndex = subQuestions.indexOf(subQuestion) + 1;
        const jsonResponse = {
            label: subQuestion,
            value: value || '',
            option: optionIndex
        };

        const updatedFifteenthCuestionResp = surveyData.fifteenthCuestionResp.filter(
            response => response.label !== subQuestion
        );
        updatedFifteenthCuestionResp.push(jsonResponse);

        setSurveyData(prevState => ({
            ...prevState,
            fifteenthCuestionResp: updatedFifteenthCuestionResp
        }));

        if (surveyData.validateFormFunction) {
            surveyData.validateFormFunction();
        }
    };

    const buildOptionRow = (label, value) => {
        return (
            <Grid key={value} container alignItems="center">
                <Typography style={{ fontSize: '14px' }}>{value}</Typography>
                <Radio
                    value={value}
                    checked={surveyData.fifteenthCuestion[label] === value}
                    onChange={() => handleOptionChange(label, value)}
                    sx={{
                        color: surveyData.fifteenthCuestion[label] === value ? 'purple' : 'default',
                        '&.Mui-checked': {
                            color: 'purple'
                        }
                    }}
                    style={{ padding: 0 }}
                />
            </Grid>
        );
    };

    const buildSubQuestion = subQuestion => {
        return (
            <div key={subQuestion} style={{ marginBottom: '16px' }}>
                <Typography style={{ fontSize: '14px', marginBottom: '8px' }}>{subQuestion}</Typography>
                <Grid container justifyContent="space-between" style={{ marginBottom: '4px' }}>
                    {['N/A', '1', '2', '3', '4', '5'].map(value => (
                        <Grid item key={value}>
                            {buildOptionRow(subQuestion, String(value))}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                18. Selecciona una opción del 1 al 5 que refleje tu nivel de satisfacción con nuestro servicio (si lo has utilizado). En esta escala, 1 significa 'Nada satisfecho' y 5 significa 'Muy satisfecho'. Si no has utilizado el servicio, selecciona 'N/A'. ¿Qué tan satisfecho estás con el servicio recibido?
                </Typography>
                {subQuestions.map(subQuestion => (
                    <div key={subQuestion} style={{ marginBottom: '8px' }}>
                        {buildSubQuestion(subQuestion)}
                    </div>
                ))}
            </Paper>
        </Container>
    );
};

export default Survey18Widget;
