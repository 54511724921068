// import React from 'react';
// import {
//     Typography, Checkbox, FormControlLabel, Container, Paper
// } from "@mui/material";

// const MultipleOptionQuestion = ({ question, options, selectedOptions, handleCheckboxValueChange }) => {

//     const buildCheckboxOption = (value, label) => {
//         return (
//             <div key={value} style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
//                 <FormControlLabel
//                     control={
//                         <Checkbox
//                             sx={{ '&.Mui-checked': { color: 'purple' } }}
//                             checked={selectedOptions.some(option => option.value === value)}
//                             onChange={(event) => handleCheckboxValueChange(value, event.target.checked)}
//                         />
//                     }
//                     label={<Typography style={{ fontSize: '14px' }}>{label}</Typography>}
//                 />
//             </div>
//         );
//     };

//     return (
//         <Container maxWidth="sm">
//             <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
//                 <Typography variant="h6" style={{ fontWeight: 'bold' }}>
//                     {question}
//                 </Typography>
//                 {options.map(option => buildCheckboxOption(option.value, option.label))}
//             </Paper>
//         </Container>
//     );
// };

// export default MultipleOptionQuestion;

import React from 'react';
import {
    Typography, Checkbox, FormControlLabel, Container, Paper
} from "@mui/material";

const MultipleOptionQuestion = ({ question, options, selectedOptions, handleCheckboxValueChange, maxSelectedOptions }) => {

    const handleCheckboxChange = (value, isChecked) => {
        // Si hay un límite y ya se alcanzó el máximo de opciones seleccionadas, no permite seleccionar más.
        if (isChecked && maxSelectedOptions && selectedOptions.length >= maxSelectedOptions) {
            return;
        }
        handleCheckboxValueChange(value, isChecked);
    };

    const buildCheckboxOption = (value, label) => {
        return (
            <div key={value} style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ '&.Mui-checked': { color: 'purple' } }}
                            checked={selectedOptions.some(option => option.label === label)}
                            onChange={(event) => handleCheckboxChange(label, event.target.checked)}
                        />
                    }
                    label={<Typography style={{ fontSize: '14px' }}>{label}</Typography>}
                />
            </div>
        );
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {question}
                </Typography>
                {options.map(option => buildCheckboxOption(option.value, option.label))}
                {maxSelectedOptions && (
                    <Typography variant="caption" style={{ marginTop: '8px', color: 'grey' }}>
                        {`Puedes seleccionar hasta ${maxSelectedOptions} opciones.`}
                    </Typography>
                )}
            </Paper>
        </Container>
    );
};

export default MultipleOptionQuestion;
