import React, { useState, useEffect } from 'react';
import { Typography, Paper, Container, Radio, FormControlLabel } from '@mui/material';

const SelectionAttributesQuestion = ({ question, options, handleOptionChange, selectedOptions }) => {
    // Usamos un objeto para mantener el estado de las opciones seleccionadas por pregunta
    const [selectedOptionsState, setSelectedOptionsState] = useState({});

    // Configurar el estado inicial basado en las opciones seleccionadas
    useEffect(() => {
        const initialState = {};
        selectedOptions.forEach(option => {
            if (option.opcion && option.value) {
                initialState[option.opcion] = option.value; // Establece el estado inicial de las opciones seleccionadas
            }
        });
        setSelectedOptionsState(initialState);
    }, [selectedOptions]);
    

    const handleOptionChangeLocal = (optionIndex, ranking) => {
        const updatedOption = { 
            opcion: optionIndex + 1, // Index base 1
            label: options[optionIndex], // Etiqueta de la opción
            value: ranking // Valor de la selección
        };

        // Actualizar el estado local
        setSelectedOptionsState(prevState => ({
            ...prevState,
            [optionIndex + 1]: ranking // Guardar el ranking por opción (index + 1)
        }));

        // Actualizar al padre con la nueva opción
        handleOptionChange(updatedOption.opcion, updatedOption);
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={1} style={{ padding: '8px', marginBottom: '20px' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    {question}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '8px' }}>
                        <Typography style={{ marginRight: '16px' }}>Mejor</Typography>
                        <Typography style={{ marginRight: '16px' }}>Igual</Typography>
                        <Typography>Peor</Typography>
                    </div>
                    {options.map((option, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                            <Typography style={{ fontSize: '14px' }}>{option}</Typography>
                            <div style={{ display: 'flex' }}>
                                <FormControlLabel 
                                    value="Mejor" 
                                    control={<Radio sx={{ '&.Mui-checked': { color: 'purple' } }} />} 
                                    checked={selectedOptionsState[index + 1] === 'Mejor'} 
                                    onChange={() => handleOptionChangeLocal(index, 'Mejor')} 
                                    style={{ marginRight: '8px' }} 
                                />
                                <FormControlLabel 
                                    value="Igual" 
                                    control={<Radio sx={{ '&.Mui-checked': { color: 'purple' } }} />} 
                                    checked={selectedOptionsState[index + 1] === 'Igual'} 
                                    onChange={() => handleOptionChangeLocal(index, 'Igual')} 
                                    style={{ marginRight: '8px' }} 
                                />
                                <FormControlLabel 
                                    value="Peor" 
                                    control={<Radio sx={{ '&.Mui-checked': { color: 'purple' } }} />} 
                                    checked={selectedOptionsState[index + 1] === 'Peor'} 
                                    onChange={() => handleOptionChangeLocal(index, 'Peor')} 
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Paper>
        </Container>
    );
};

export default SelectionAttributesQuestion;
