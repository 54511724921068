// LandingPage.js
import React, { useEffect, useState } from "react";
import {
  Typography, Container, Button, Box, Paper
} from "@mui/material";

import { question1, optionsSurvey1, question2, optionsSurvey2, question3, optionsSurvey3, question4, optionsSurvey4, question5, optionsSurvey5, question6, optionsSurvey6, question7, optionsSurvey7, question8, optionsSurvey8, question9, optionsSurvey9, question10, optionsSurvey10, question11, optionsSurvey11, question12, optionsSurvey12, question13, optionsSurvey13, question14, optionsSurvey14, question15, optionsSurvey15, question16, optionsSurvey16, question17, optionsSurvey17, question18, question19, question20, question21, question22, question23, optionsSurvey23, question24, optionsSurvey24, question25, optionsSurvey25, question26, optionsSurvey26, question27, optionsSurvey27, question28 } from './components/SecondSurvey/SurveyQuestions/SurveyQuestions';

import ely_survey from './assets/ely_survey.png'
import CedulaPage from './CedulaPage';
import Swal from "sweetalert2";

import { db, firebase } from './firebase';
import MultipleOptionQuestion from "./components/SecondSurvey/SecondSurveyWidgets/MultipleOptionQuestion";
import SingleOptionQuestion from "./components/SecondSurvey/SecondSurveyWidgets/SingleOptionQuestion";
import TextFieldQuestion from "./components/SecondSurvey/SecondSurveyWidgets/TextFieldQuestion";
import SelectionInOrderQuestion from "./components/SecondSurvey/SecondSurveyWidgets/SelectionInOrderQuestion";
import SelectionAttributesQuestion from "./components/SecondSurvey/SecondSurveyWidgets/SelectionAttributesQuestion";
import Survey18Widget from "./components/SecondSurvey/SecondSurveyWidgets/Survey18Widget";
import HorizontalOptionsQuestion from "./components/SecondSurvey/SecondSurveyWidgets/HorizontalOptionsQuestion";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

const LandingPage = () => {

  const [nombres, setNombres] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [perfil, setPerfil] = useState('');
  const [costoPlan, setCostoPlan] = useState('');
  const [fechaInstalacion, setFechaInstalacion] = useState('');
  const [nodo, setNodo] = useState('');

  const [loadingButton, setLoadingButton] = useState(false);

  useEffect(() => {
  }, []);

  const [showLandingPage, setShowLandingPage] = useState(false); // Estado para controlar la visibilidad de la LandingPage

  const [name, setName] = useState('Lian');
  const [lastName, setLastName] = useState('Mejia');
  const [cedula, setCedula] = useState('0953416260');
  const [responseMessage, setResponseMessage] = useState('');

  const consultCedulaUser = async () => {
    try {
      const consult_user = firebase.functions().httpsCallable("consult_user");

      const data = {
        cedula: '0953416260',
      }

      const response = await consult_user(data);

      console.log(response.data.message); // Mensaje de éxito de la Cloud Function
    } catch (error) {
      console.error("Error al llamar a la Cloud Function:", error);
    }
  };

  const consultUser = async () => {
    setLoadingButton(true)
    try {
      const relational_survey = firebase.functions().httpsCallable("relational_survey");

      const data = {
        uid: uid,
        first_question: question1,
        first_question_resp: selectedOptionSurvey1,
        second_question: question2,
        second_question_resp: selectedOptionSurvey2,
        third_question: question3,
        third_question_resp: selectedOptionsSurvey3,
        fourth_question: question4,
        fourth_question_resp: selectedOptionsSurvey4,
        fifth_question: question5,
        fifth_question_resp: selectedOptionSurvey5,
        sixth_question: question6,
        sixth_question_resp: selectedOptionsSurvey6,
        seventh_question: question7,
        seventh_question_resp: selectedOptionSurvey7,
        eighth_question: question8,
        eighth_question_resp: selectedOptionsSurvey8,
        ninth_question: question9,
        ninth_question_resp: selectedOptionSurvey9,
        tenth_question: question10,
        tenth_question_resp: answerSurvey10,
        eleventh_question: question11,
        eleventh_question_resp: selectedOptionsSurvey11,
        twelfth_question: question12,
        twelfth_question_resp: selectedOptionsSurvey12,
        thirteenth_question: question13,
        thirteenth_question_resp: selectedOptionsSurvey13,
        fourteenth_question: question14,
        fourteenth_question_resp: selectedOptionsSurvey14,
        fifteenth_question: question15,
        fifteenth_question_resp: selectedOptionsSurvey15,
        sixteenth_question: question16,
        sixteenth_question_resp: selectedOptionsSurvey16,
        seventeenth_question: question17,
        seventeenth_question_resp: selectedOptionSurvey17,
        eighteenth_question: question18,
        eighteenth_question_resp: surveyData18.fifteenthCuestionResp,
        nineteenth_question: question19,
        nineteenth_question_resp: selectedOptionSurvey19,
        twentieth_cuestion: question20,
        twentieth_cuestion_resp: selectedOptionSurvey20,
        twenty_first_cuestion: question21,
        twenty_first_cuestion_resp: selectedOptionSurvey21,
        twenty_second_cuestion: question22,
        twenty_second_cuestion_resp: selectedOptionSurvey22,
        twenty_third_cuestion: question23,
        twenty_third_cuestion_resp: selectedOptionSurvey23,
        twenty_fourth_cuestion: question24,
        twenty_fourth_cuestion_resp: selectedOptionsSurvey24,
        twenty_fifth_cuestion: question25,
        twenty_fifth_cuestion_resp: selectedOptionsSurvey25,
        twenty_sixth_cuestion: question26,
        twenty_sixth_cuestion_resp: selectedOptionSurvey26,
        twenty_seventh_cuestion: question27,
        twenty_seventh_cuestion_resp: selectedOptionSurvey27,
        twenty_eighth_cuestion: question28,
        twenty_eighth_cuestion_resp: answerSurvey28,
        name: nombres,
        email: email,
        perfil: perfil,
        costoPlan: costoPlan,
        fechaInstalacion: fechaInstalacion,
        nodo: nodo
        /* device: 'Web' */
      }

      const response = await relational_survey(data);

      console.log(response.data.message); // Mensaje de éxito de la Cloud Function

      if (response.data.message === 'La encuesta se ha registrado exitosamente.') {
        Swal.fire({
          icon: "success",
          title: "Encuesta de satisfacción realizada con exito",
          text: "Felicidades, ya estás participando. Atento a nuestros medios oficiales donde daremos a conocer a los ganadores."
        });
        setTimeout(function () {
          window.location.reload();
        }, 8000);
        setLoadingButton(false)
      } else {
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error",
          text: "Por favor vuelva a intentarlo dentro de un momento"
        });
        setLoadingButton(false)
      }
    } catch (error) {
      console.error("Error al llamar a la Cloud Function:", error);
      setLoadingButton(false)
    }
  };

  const [selectedOptionSurvey1, setSelectedOptionSurvey1] = useState(null);
  const [selectedOptionSurvey2, setSelectedOptionSurvey2] = useState(null);
  const [selectedOptionsSurvey3, setSelectedOptionsSurvey3] = useState([]);
  const [selectedOptionsSurvey4, setSelectedOptionsSurvey4] = useState([]);
  const [selectedOptionSurvey5, setSelectedOptionSurvey5] = useState(null);
  const [selectedOptionsSurvey6, setSelectedOptionsSurvey6] = useState([]);
  const [selectedOptionSurvey7, setSelectedOptionSurvey7] = useState(null);
  const [selectedOptionsSurvey8, setSelectedOptionsSurvey8] = useState([]);
  const [selectedOptionSurvey9, setSelectedOptionSurvey9] = useState(null);
  const [answerSurvey10, setAnswerSurvey10] = useState('');
  const [selectedOptionsSurvey11, setSelectedOptionsSurvey11] = useState([]);
  const [selectedOptionsSurvey12, setSelectedOptionsSurvey12] = useState([]);
  const [selectedOptionsSurvey13, setSelectedOptionsSurvey13] = useState([]);
  const [selectedOptionsSurvey14, setSelectedOptionsSurvey14] = useState([]);
  const [selectedOptionsSurvey15, setSelectedOptionsSurvey15] = useState([]);
  const [selectedOptionsSurvey16, setSelectedOptionsSurvey16] = useState([]);
  const [selectedOptionSurvey17, setSelectedOptionSurvey17] = useState(null);

  const [selectedOptionSurvey19, setSelectedOptionSurvey19] = useState(null);
  const [selectedOptionSurvey20, setSelectedOptionSurvey20] = useState(null);
  const [selectedOptionSurvey21, setSelectedOptionSurvey21] = useState(null);
  const [selectedOptionSurvey22, setSelectedOptionSurvey22] = useState(null);

  const [selectedOptionSurvey23, setSelectedOptionSurvey23] = useState(null);
  const [selectedOptionsSurvey24, setSelectedOptionsSurvey24] = useState([]);
  const [selectedOptionsSurvey25, setSelectedOptionsSurvey25] = useState([]);
  const [selectedOptionSurvey26, setSelectedOptionSurvey26] = useState(null);
  const [selectedOptionSurvey27, setSelectedOptionSurvey27] = useState(null);
  const [answerSurvey28, setAnswerSurvey28] = useState('');
  const [surveyData16, setSurveyData16] = useState({
    sixteenthCuestion: null,
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const [surveyData17, setSurveyData17] = useState({
    sixteenthCuestion: null,
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const [surveyData18, setSurveyData18] = useState({
    selectedOptionSurvey1: null,
    selectedOptionSurvey2: null,
    // ...otros estados de las encuestas
    fifteenthCuestion: {}, // Aquí podrías inicializar los datos para la 15ª pregunta según lo necesario
    fifteenthCuestionResp: [], // Aquí podrías inicializar los datos de respuesta para la 15ª pregunta según lo necesario
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const [selectedOptionsSurvey20, setSelectedOptionsSurvey20] = useState([]);

  const [selectedOptionsSurvey21, setSelectedOptionsSurvey21] = useState({
    twentyFirstCuestion: '', // Inicializa los datos para la pregunta 21 según sea necesario
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });


  const [surveyData, setSurveyData] = useState({
    selectedOptionSurvey1: null,
    selectedOptionSurvey2: null,
    // ...otros estados de las encuestas
    fifteenthCuestion: {}, // Aquí podrías inicializar los datos para la 15ª pregunta según lo necesario
    fifteenthCuestionResp: [], // Aquí podrías inicializar los datos de respuesta para la 15ª pregunta según lo necesario
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const [surveyData151, setSurveyData151] = useState({
    fifteenthCuestion: {}, // Puedes inicializar los datos de la pregunta 15 aquí según sea necesario
    fifteenthCuestionResp: [], // Puedes inicializar los datos de respuesta de la pregunta 15 aquí según sea necesario
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const [surveyData152, setSurveyData152] = useState({
    fifteenthCuestion: {}, // Puedes inicializar los datos de la pregunta 15 aquí según sea necesario
    fifteenthCuestionResp: [], // Puedes inicializar los datos de respuesta de la pregunta 15 aquí según sea necesario
    validateFormFunction: () => { } // Agrega cualquier otra función necesaria aquí
  });

  const handleRadioValueChangeSurvey1 = (value) => {
    setSelectedOptionSurvey1(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey2 = (value) => {
    setSelectedOptionSurvey2(value);
    console.log(value); // Simulación de actualización del estado
  };

  // const handleCheckboxValueChangeSurvey3 = (value, checked) => {
  //   if (checked) {
  //     setSelectedOptionsSurvey3(prev => [...prev, { value }]);
  //   } else {
  //     setSelectedOptionsSurvey3(prev => prev.filter(option => option.value !== value));
  //   }
  // };

  const handleCheckboxValueChangeSurvey3 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey3(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey3(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleCheckboxValueChangeSurvey4 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey4(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey4(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleRadioValueChangeSurvey5 = (value) => {
    setSelectedOptionSurvey5(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleCheckboxValueChangeSurvey6 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey6(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey6(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleRadioValueChangeSurvey7 = (value) => {
    setSelectedOptionSurvey7(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleCheckboxValueChangeSurvey8 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey8(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey8(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleRadioValueChangeSurvey9 = (value) => {
    setSelectedOptionSurvey9(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleTextFieldChange10 = (value) => {
    setAnswerSurvey10(value);
  };

  const handleCheckboxValueChangeSurvey11 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey11(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey11(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleCheckboxValueChangeSurvey12 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey12(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey12(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleCheckboxValueChangeSurvey13 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey13(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey13(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleCheckboxValueChangeSurvey14 = (value) => {
    setSelectedOptionsSurvey14(value);
  };

  const handleCheckboxValueChangeSurvey15 = (value) => {
    setSelectedOptionsSurvey15(value);
  };

  const handleOptionChangeSurvey16 = (option, updatedOption) => {
    const updatedOptions = selectedOptionsSurvey16.filter(item => item.opcion !== updatedOption.opcion); // Elimina la opción si ya existe
    setSelectedOptionsSurvey16([...updatedOptions, updatedOption]); // Guarda la opción actualizada
    // Aquí puedes agregar la lógica para guardar en Firestore
  };

  const handleRadioValueChangeSurvey17 = (value) => {
    setSelectedOptionSurvey17(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey19 = (value) => {
    setSelectedOptionSurvey19(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey20 = (value) => {
    setSelectedOptionSurvey20(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey21 = (value) => {
    setSelectedOptionSurvey21(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey22 = (value) => {
    setSelectedOptionSurvey22(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleCheckboxValueChangeSurvey20 = (value, isChecked) => {
    const optionLabel = getOptionLabel20(value);
    if (isChecked) {
      setSelectedOptionsSurvey20([...selectedOptionsSurvey20, { value, label: optionLabel }]);
    } else {
      setSelectedOptionsSurvey20(selectedOptionsSurvey20.filter(option => option.value !== value));
    }
  };

  const getOptionLabel20 = (value) => {
    switch (value) {
      case 1:
        return 'Restaurantes';
      case 2:
        return 'Gimnasios';
      case 3:
        return 'Eventos deportivos';
      case 4:
        return 'Eventos culturales';
      case 5:
        return 'Eventos de entretenimiento';
      default:
        return '';
    }
  };

  const handleRadioValueChangeSurvey23 = (value) => {
    setSelectedOptionSurvey23(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleCheckboxValueChangeSurvey24 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey24(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey24(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleCheckboxValueChangeSurvey25 = (label, checked) => {
    if (checked) {
      setSelectedOptionsSurvey25(prev => [...prev, { label }]);
    } else {
      setSelectedOptionsSurvey25(prev => prev.filter(option => option.label !== label));
    }
  };

  const handleRadioValueChangeSurvey26 = (value) => {
    setSelectedOptionSurvey26(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleRadioValueChangeSurvey27 = (value) => {
    setSelectedOptionSurvey27(value);
    console.log(value); // Simulación de actualización del estado
  };

  const handleTextFieldChange28 = (value) => {
    setAnswerSurvey28(value);
  };

  const printSelectedValue = () => {
    console.log("Valor seleccionado en Survey1Widget:", selectedOptionSurvey1);
    console.log("Valor seleccionado en Survey2Widget:", selectedOptionSurvey2);
    console.log("Valor seleccionado en Survey3Widget:", selectedOptionsSurvey3);
    console.log("Valor seleccionado en Survey4Widget:", selectedOptionsSurvey4);
    console.log("Valor seleccionado en Survey5Widget:", selectedOptionSurvey5);
    console.log("Valor seleccionado en Survey6Widget:", selectedOptionsSurvey6);
    console.log("Valor seleccionado en Survey7Widget:", selectedOptionSurvey7);
    console.log("Valor seleccionado en Survey8Widget:", selectedOptionsSurvey8);
    console.log("Valor seleccionado en Survey9Widget:", selectedOptionSurvey9);
    console.log("Valor seleccionado en Survey10Widget:", answerSurvey10);
    console.log("Valor seleccionado en Survey11Widget:", selectedOptionsSurvey11);
    console.log("Valor seleccionado en Survey12Widget:", selectedOptionsSurvey12);
    console.log("Valor seleccionado en Survey13Widget:", selectedOptionsSurvey13);
    console.log("Valor seleccionado en Survey14Widget:", selectedOptionsSurvey14);
    console.log("Valor seleccionado en Survey15Widget:", selectedOptionsSurvey15);
    console.log("Valor seleccionado en Survey16Widget:", selectedOptionsSurvey16);
    console.log("Valor seleccionado en Survey17Widget:", selectedOptionSurvey17);
    console.log("Valor seleccionado en Survey18Widget:", surveyData18.fifteenthCuestionResp);
    console.log("Valor seleccionado en Survey19Widget:", selectedOptionSurvey19);
    console.log("Valor seleccionado en Survey20Widget:", selectedOptionSurvey20);
    console.log("Valor seleccionado en Survey21Widget:", selectedOptionSurvey21);
    console.log("Valor seleccionado en Survey22Widget:", selectedOptionSurvey22);
    console.log("Valor seleccionado en Survey23Widget:", selectedOptionSurvey23);
    console.log("Valor seleccionado en Survey24Widget:", selectedOptionsSurvey24);
    console.log("Valor seleccionado en Survey25Widget:", selectedOptionsSurvey25);
    console.log("Valor seleccionado en Survey26Widget:", selectedOptionSurvey26);
    console.log("Valor seleccionado en Survey27Widget:", selectedOptionSurvey27);
    console.log("Valor seleccionado en Survey28Widget:", answerSurvey28);
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    const validations = [
      { field: selectedOptionSurvey1, questionNumber: 1 },
      { field: selectedOptionSurvey2, questionNumber: 2 },
      { field: selectedOptionsSurvey3, questionNumber: 3 },
      { field: selectedOptionsSurvey4, questionNumber: 4 },
      { field: selectedOptionSurvey5, questionNumber: 5 },
      { field: selectedOptionsSurvey6, questionNumber: 6 },
      { field: selectedOptionSurvey7, questionNumber: 7 },
      { field: selectedOptionsSurvey8, questionNumber: 8 },
      { field: selectedOptionSurvey9, questionNumber: 9 },
      { field: answerSurvey10, questionNumber: 10, minLength: 1 }, // Requiere al menos 1 carácter
      { field: selectedOptionsSurvey11, questionNumber: 11 },
      { field: selectedOptionsSurvey12, questionNumber: 12 },
      { field: selectedOptionsSurvey13, questionNumber: 13 },
      { field: selectedOptionsSurvey14, questionNumber: 14, minLength: 10 },
      { field: selectedOptionsSurvey15, questionNumber: 15, minLength: 10 },
      { field: selectedOptionsSurvey16, questionNumber: 16, minLength: 10 },
      { field: selectedOptionSurvey17, questionNumber: 17 },
      { field: surveyData18.fifteenthCuestionResp, questionNumber: 18, minLength: 12 },
      { field: selectedOptionSurvey19, questionNumber: 19 },
      { field: selectedOptionSurvey20, questionNumber: 20 },
      { field: selectedOptionSurvey21, questionNumber: 21 },
      { field: selectedOptionSurvey22, questionNumber: 22 },
      { field: selectedOptionSurvey23, questionNumber: 23 },
      { field: selectedOptionsSurvey24, questionNumber: 24 },
      { field: selectedOptionsSurvey25, questionNumber: 25 },
      { field: selectedOptionSurvey26, questionNumber: 26 },
      { field: selectedOptionSurvey27, questionNumber: 27 },
      { field: answerSurvey28, questionNumber: 28, minLength: 1 } // Requiere al menos 1 carácter
    ];

    for (const validation of validations) {
      const { field, questionNumber, minLength } = validation;

      // Validación para campo null o undefined
      if (field === null || field === undefined || (Array.isArray(field) && field.length === 0)) {
        setErrorMessage(`Por favor complete la pregunta ${questionNumber} para poder finalizar la encuesta`);
        return;
      }

      // Validación para campos string o array vacíos según el mínimo requerido
      if (minLength && field.length < minLength) {
        setErrorMessage(`Por favor complete la pregunta ${questionNumber} para poder finalizar la encuesta`);
        return;
      }
    }

    // Si todos los campos están completos, se llama a la función de envío y se limpia el mensaje de error
    setErrorMessage('');
    consultUser();
  };

  return (
    <Container style={{ padding: '0px' }} sx={{ padding: '0px' }}>
      {!showLandingPage ? ( // Mostrar la pantalla de cédula si showLandingPage es false
        <CedulaPage setShowLandingPage={setShowLandingPage} setUid={setUid} setNombres={setNombres} setEmail={setEmail} setPerfil={setPerfil} setCostoPlan={setCostoPlan} setFechaInstalacion={setFechaInstalacion} setNodo={setNodo} />
      ) : (
        <Container maxWidth="lg" style={{ padding: '0px' }} sx={{ padding: '0px' }}>
          <Container maxWidth="lg" style={{ backgroundColor: 'transparent' }}>
            <Paper elevation={0} style={{ padding: '8px', marginBottom: '20px', textAlign: 'center', backgroundColor: 'transparent' }}>
              <img src={ely_survey} alt="soei" width="100" />
              <Box py={1}>
                <Typography variant="h6" style={{ fontWeight: 'bold', color: 'purple' }}>
                  Apreciado {nombres}
                </Typography>
              </Box>
              <Typography variant="subtitle2">
                Todos nuestros clientes que nos ayuden finalizando la encuesta participan automáticamente de un sorteo para ganar premios
              </Typography>
            </Paper>
          </Container>
          <SingleOptionQuestion
            question={question1}
            options={optionsSurvey1}
            selectedOption={selectedOptionSurvey1}
            handleRadioValueChange={handleRadioValueChangeSurvey1}
          />
          <SingleOptionQuestion
            question={question2}
            options={optionsSurvey2}
            selectedOption={selectedOptionSurvey2}
            handleRadioValueChange={handleRadioValueChangeSurvey2}
          />
          <MultipleOptionQuestion
            question={question3}
            options={optionsSurvey3}
            selectedOptions={selectedOptionsSurvey3}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey3}
          />
          <MultipleOptionQuestion
            question={question4}
            options={optionsSurvey4}
            selectedOptions={selectedOptionsSurvey4}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey4}
          />
          <SingleOptionQuestion
            question={question5}
            options={optionsSurvey5}
            selectedOption={selectedOptionSurvey5}
            handleRadioValueChange={handleRadioValueChangeSurvey5}
          />
          <MultipleOptionQuestion
            question={question6}
            options={optionsSurvey6}
            selectedOptions={selectedOptionsSurvey6}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey6}
          />
          <SingleOptionQuestion
            question={question7}
            options={optionsSurvey7}
            selectedOption={selectedOptionSurvey7}
            handleRadioValueChange={handleRadioValueChangeSurvey7}
          />
          <MultipleOptionQuestion
            question={question8}
            options={optionsSurvey8}
            selectedOptions={selectedOptionsSurvey8}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey8}
          />
          <SingleOptionQuestion
            question={question9}
            options={optionsSurvey9}
            selectedOption={selectedOptionSurvey9}
            handleRadioValueChange={handleRadioValueChangeSurvey9}
          />
          <TextFieldQuestion
            question={question10}
            answer={answerSurvey10}
            handleTextFieldChange={handleTextFieldChange10}
          />
          <MultipleOptionQuestion
            question={question11}
            options={optionsSurvey11}
            selectedOptions={selectedOptionsSurvey11}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey11}
            maxSelectedOptions={2}
          />
          <MultipleOptionQuestion
            question={question12}
            options={optionsSurvey12}
            selectedOptions={selectedOptionsSurvey12}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey12}
          />
          <MultipleOptionQuestion
            question={question13}
            options={optionsSurvey13}
            selectedOptions={selectedOptionsSurvey13}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey13}
          />
          <SelectionInOrderQuestion
            question={question14}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey14}
            options={optionsSurvey14}
          />
          <SelectionInOrderQuestion
            question={question15}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey15}
            options={optionsSurvey15}
          />
          <SelectionAttributesQuestion question={question16} handleOptionChange={handleOptionChangeSurvey16} options={optionsSurvey16} selectedOptions={selectedOptionsSurvey16} />
          <SingleOptionQuestion
            question={question17}
            options={optionsSurvey17}
            selectedOption={selectedOptionSurvey17}
            handleRadioValueChange={handleRadioValueChangeSurvey17}
          />
          <Survey18Widget surveyData={surveyData18} setSurveyData={setSurveyData18} />
          <HorizontalOptionsQuestion
            question={question19}
            selectedValue={selectedOptionSurvey19}
            handleRadioValueChange={handleRadioValueChangeSurvey19}
            range={10}  // Puedes cambiar el rango, por ejemplo, 5 para un rango de 0 a 5
            minLabel="Nada probable"
            maxLabel="Muy probable"
          />
          <HorizontalOptionsQuestion
            question={question20}
            selectedValue={selectedOptionSurvey20}
            handleRadioValueChange={handleRadioValueChangeSurvey20}
            range={10}  // Puedes cambiar el rango, por ejemplo, 5 para un rango de 0 a 5
            minLabel="Nada probable"
            maxLabel="Muy probable"
          />
          <HorizontalOptionsQuestion
            question={question21}
            selectedValue={selectedOptionSurvey21}
            handleRadioValueChange={handleRadioValueChangeSurvey21}
            range={10}  // Puedes cambiar el rango, por ejemplo, 5 para un rango de 0 a 5
            minLabel="Nada probable"
            maxLabel="Muy probable"
          />
          <HorizontalOptionsQuestion
            question={question22}
            selectedValue={selectedOptionSurvey22}
            handleRadioValueChange={handleRadioValueChangeSurvey22}
            range={10}  // Puedes cambiar el rango, por ejemplo, 5 para un rango de 0 a 5
            minLabel="Nada probable"
            maxLabel="Muy probable"
          />
          <SingleOptionQuestion
            question={question23}
            options={optionsSurvey23}
            selectedOption={selectedOptionSurvey23}
            handleRadioValueChange={handleRadioValueChangeSurvey23}
          />
          <MultipleOptionQuestion
            question={question24}
            options={optionsSurvey24}
            selectedOptions={selectedOptionsSurvey24}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey24}
          />
          <MultipleOptionQuestion
            question={question25}
            options={optionsSurvey25}
            selectedOptions={selectedOptionsSurvey25}
            handleCheckboxValueChange={handleCheckboxValueChangeSurvey25}
          />
          <SingleOptionQuestion
            question={question26}
            options={optionsSurvey26}
            selectedOption={selectedOptionSurvey26}
            handleRadioValueChange={handleRadioValueChangeSurvey26}
          />
          <SingleOptionQuestion
            question={question27}
            options={optionsSurvey27}
            selectedOption={selectedOptionSurvey27}
            handleRadioValueChange={handleRadioValueChangeSurvey27}
          />
          <TextFieldQuestion
            question={question28}
            answer={answerSurvey28}
            handleTextFieldChange={handleTextFieldChange28}
          />
          <Box pb={2}>
            <Container maxWidth="sm">
              <LoadingButton
                loading={loadingButton}
                endIcon={loadingButton === true ? <SendIcon /> : ''} loadingPosition="end"

                color="secondary"
                fullWidth
                variant="contained"
                onClick={() => { /* printSelectedValue() */handleSubmit()/* guardarNumero() */ }}
              >Finalizar encuesta</LoadingButton>
            </Container>
          </Box>

          {/* Mostrar mensaje de error si existe */}
          {errorMessage && (
            <Container maxWidth="sm">
              <Typography color="error" style={{ marginBottom: '16px' }}>
                {errorMessage}
              </Typography>
            </Container>
          )}

        </Container >

      )}
    </Container>
  );
};

export default LandingPage;
