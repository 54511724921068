import React, { useEffect, useState } from 'react';
import { Button, Container, FormControlLabel, Radio, Typography, Paper, Box, FormControl, Grid } from '@mui/material';
import Swal from "sweetalert2";
import { db, firebase } from '../../firebase';
import CedulaPageTablets from './CedulaPageTablets';
import first from './../../assets/emojis/1.png'
import second from './../../assets/emojis/2.png'
import third from './../../assets/emojis/3.png'
import fourth from './../../assets/emojis/4.png'
import five from './../../assets/emojis/5.jpg'
import si from './../../assets/emojis/si.png'
import no from './../../assets/emojis/no.png'
import './styles.css';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

export const EncuestaTablets = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [firstQuestion, setFirstQuestion] = useState(null);
    const [secondQuestion, setSecondQuestion] = useState(null);
    const [thirdQuestion, setThirdQuestion] = useState(null);
    // Estados para almacenar las respuestas de la encuesta
    const [nivelFacilidad, setNivelFacilidad] = useState('');
    const [presentacionCordialidad, setPresentacionCordialidad] = useState('');
    const [asesoramientoUbicacion, setAsesoramientoUbicacion] = useState('');
    const [satisfaccionVisita, setSatisfaccionVisita] = useState('');
    const [probabilidadRecomendacion, setProbabilidadRecomendacion] = useState('');
    const [comentario, setComentario] = useState('');

    const [nombres, setNombres] = useState('');
    const [uid, setUid] = useState('');
    const [email, setEmail] = useState('');
    const [showLandingPage, setShowLandingPage] = useState(false);
    // Función para manejar el envío de la encuesta
    const handleSubmit = (event) => {
        event.preventDefault();
        // Aquí puedes enviar las respuestas a tu servidor o realizar cualquier otra acción
        console.log('firstQuestion', firstQuestion);
        console.log('secondQuestion', secondQuestion);
        console.log('thirdQuestion', thirdQuestion);
        // Limpia los estados después de enviar la encuesta
        db.collection('encuestas').doc('centro_experiencias').collection('encuesta').doc(`${uid}`).set({
            cedula: uid,
            nombre: nombres,
            email: email,
            firstQuestion: firstQuestion,
            secondQuestion: secondQuestion,
            thirdQuestion: thirdQuestion,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    text: 'Gracias por compartir su experiencia',
                })
                console.log('Encuesta guardada correctamente en Firestore');
                // handleClose(); // Cerrar modal después de enviar la encuesta
            })
            .catch((error) => {
                console.error('Error al guardar la encuesta:', error);
            });
        setNivelFacilidad('');
        setPresentacionCordialidad('');
        setAsesoramientoUbicacion('');
        setSatisfaccionVisita('');
        setProbabilidadRecomendacion('');
        setComentario('');
    };

    const handleImageClick1 = (value) => {
        console.log('Valor de la imagen 1:', value);
        setFirstQuestion(value);
        /* if (thirdQuestion != null) {
            setTimeout(() => {
                SendSurvey()
            }, 1000);
        } */
    };

    const handleImageClick2 = (value) => {
        console.log('Valor de la imagen 2:', value);
        setSecondQuestion(value);
    };


    const handleImageClick3 = (value) => {
        console.log('Valor de la imagen 3:', value);
        setThirdQuestion(value);
        /* if (firstQuestion != null) {
            setTimeout(() => {
                SendSurvey()
            }, 1000);
        } */
    };

    const SendSurvey = async () => {
        setIsLoading(true);
        try {
            const send_survey = firebase.functions().httpsCallable("send_survey");

            const data = {
                cedula: uid,
                coleccion1: 'encuestas',
                documento: 'centro_experiencias',
                coleccion2: 'encuesta',
                firstQuestion: firstQuestion,
                secondQuestion: secondQuestion,
                thirdQuestion: thirdQuestion,
                name: nombres,
                email: email
            }

            const response = await send_survey(data);

            console.log(response.data.message); // Mensaje de éxito de la Cloud Function

            if (response.data.message === 'La encuesta se ha registrado exitosamente.') {
                Swal.fire({
                    icon: "success",
                    title: "Encuesta de satisfacción realizada con exito",
                    text: "Felicidades, ya estás participando. Atento a nuestros medios oficiales donde daremos a conocer a los ganadores."
                });
                setTimeout(function () {
                    window.location.reload();
                }, 8000);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Ha ocurrido un error",
                    text: "Por favor vuelva a intentarlo dentro de un momento"
                });
            }
        } catch (error) {
            console.error("Error al llamar a la Cloud Function:", error);
        } finally {
            setIsLoading(false); // Establecer isLoading a false después de que se completa la consulta
        }
    }

    useEffect(() => {
        if (firstQuestion != null && thirdQuestion != null) {
            setTimeout(() => {
                SendSurvey()
            }, 500);
        }
    }, [firstQuestion, thirdQuestion]);

    return (
        <Container style={{ padding: '10px' }}>
            {!showLandingPage ? ( // Mostrar la pantalla de cédula si showLandingPage es false
                <CedulaPageTablets setShowLandingPage={setShowLandingPage} setUid={setUid} setNombres={setNombres} setEmail={setEmail} />
            ) : (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    height='100vh'
                >
                    <Container maxWidth='xl' style={{ padding: '0px', margin: "0px" }}>
                        <Paper elevation={1} style={{ padding: '10px', margin: "0px" }}>
                            {/* <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '20px', color: 'purple' }}>
                                Encuesta de Satisfaccion de Servicio
                            </Typography> */}
                            <Box marginBottom="30px">
                                <Typography variant="h5" component="legend"><strong>¿Cómo califica la presentación y cordialidad por parte del asesor?</strong></Typography>
                                <Box mt={2} display="flex"
                                    justifyContent="space-between"
                                    flexWrap="nowrap">
                                    {[fourth, third, second, first, five].map((image, index) => (
                                        <img key={index} src={image} alt={`Emoción ${index + 1}`} width='110' height='110' style={{ cursor: 'pointer' }} className={firstQuestion === index + 1 ? 'selected' : ''} onClick={() => handleImageClick1(index + 1)} />
                                    ))}
                                </Box>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '11px', marginTop: '4px' }}>
                                    <Typography style={{ fontSize: '14px' }}>Muy insatisfecho</Typography>
                                    <Typography style={{ fontSize: '14px' }}>Muy satisfecho</Typography>
                                </div>
                            </Box>
                            {/* <Box marginBottom="20px">
                                <Typography component="legend" ><strong>2. ¿El asesor que le atendió fue capaz de resolver su requerimiento en una única interacción?</strong> (S/N)</Typography>
                                <Box mt={1} display="flex"
                                    justifyContent="space-around"
                                    flexWrap="nowrap">
                                    {[si, no].map((image, index) => (
                                        <img key={index} src={image} alt={`Emoción ${index + 1}`} width='50' height='50' style={{ cursor: 'pointer' }} className={secondQuestion === index + 1 ? 'selectedRectangle' : ''} onClick={() => handleImageClick2(index + 1)} />
                                    ))}
                                </Box>

                            </Box> */}
                            <Box marginBottom="20px">
                                <Typography variant="h5" component="legend" ><strong>¿Cuál es su nivel de satisfacción general con su visita al Centro de experiencia?</strong></Typography>
                                <Box mt={2} display="flex"
                                    justifyContent="space-between"
                                    flexWrap="nowrap">
                                    {[fourth, third, second, first, five].map((image, index) => (
                                        <img key={index} src={image} alt={`Emoción ${index + 1}`} width='110' height='110' style={{ cursor: 'pointer' }} className={thirdQuestion === index + 1 ? 'selected' : ''} onClick={() => handleImageClick3(index + 1)} />
                                    ))}
                                </Box>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '11px', marginTop: '4px' }}>
                                    <Typography style={{ fontSize: '14px' }}>Muy insatisfecho</Typography>
                                    <Typography style={{ fontSize: '14px' }}>Muy satisfecho</Typography>
                                </div>
                            </Box>
                            {
                                isLoading ? <LoadingButton
                                    loading={isLoading}
                                    endIcon={isLoading === true ? <SendIcon /> : ''}
                                    loadingPosition="end"
                                    disabled={firstQuestion === null || secondQuestion === null || thirdQuestion === null}
                                    fullWidth
                                    color='secondary'
                                    variant="text"
                                    onClick={() => SendSurvey()}>
                                    Enviando encuesta...
                                </LoadingButton> : ''
                            }
                        </Paper>
                    </Container>
                </Grid>

            )}
        </Container>
    );
};

