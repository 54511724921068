export const question1 = "1. Género";

export const optionsSurvey1 = [
    { value: 1, label: 'Masculino' },
    { value: 2, label: 'Femenino' },
    { value: 3, label: 'Prefiero no decir' }
];

export const question2 = "2. Rango de edad";

export const optionsSurvey2 = [
    { value: 1, label: '18 a 25 años' },
    { value: 2, label: '26 a 35 años' },
    { value: 3, label: '36 a 50 años' },
    { value: 4, label: '+50 años' }
];

export const question3 = "3. ¿Cuáles son tus pasatiempos o actividades favoritas en tu tiempo libre? (opción múltiple)";

export const optionsSurvey3 = [
    { value: 1, label: 'Pasar tiempo en casa' },
    { value: 2, label: 'Salir al cine' },
    { value: 3, label: 'Salir a comer' },
    { value: 4, label: 'Ir al gimnasio' },
    { value: 5, label: 'Practicar un deporte' },
    { value: 6, label: 'Leer un libro' },
    { value: 7, label: 'Hacer un viaje' },
    { value: 8, label: 'Otro' }
];

export const question4 = "4. ¿Qué dispositivos electrónicos posees?";

export const optionsSurvey4 = [
    { value: 1, label: 'Teléfono móvil (celular)' },
    { value: 2, label: 'Televisión inteligente (Smart tv)' },
    { value: 3, label: 'Computadora' },
    { value: 4, label: 'Tablet' },
    { value: 5, label: 'Alexa' },
    { value: 6, label: 'Consola de videojuegos' },
    { value: 7, label: 'Otro' }
];

export const question5 = "5. ¿Cuántas personas en tu domicilio hacen uso del servicio de internet generalmente? (una opción)";

export const optionsSurvey5 = [
    { value: 1, label: '1-2' },
    { value: 2, label: '3-5' },
    { value: 3, label: '6+' }
];

export const question6 = "6. ¿Qué dispositivos conectas normalmente en tu domicilio al servicio de internet? (opción múltiple)";

export const optionsSurvey6 = [
    { value: 1, label: 'Teléfono móvil (celular)' },
    { value: 2, label: 'Televisión inteligente (Smart tv)' },
    { value: 3, label: 'Computadora (de escritorio o portátil)' },
    { value: 4, label: 'Tableta' },
    { value: 5, label: 'Cámaras de seguridad' },
    { value: 6, label: 'Consola de videojuegos' },
    { value: 7, label: 'Dispositivos inteligentes (Alexa, Chromecast, Google Home, bombillas)' },
    { value: 8, label: 'Dispositivos streaming (Tvbox)' },
    { value: 9, label: 'Otro' },
];

export const question7 = "7. ¿Cuántos dispositivos conectas de forma simultánea al servicio de internet en tu domicilio? (una opción)";

export const optionsSurvey7 = [
    { value: 1, label: '1-3' },
    { value: 2, label: '4-6' },
    { value: 3, label: '7+' }
];

export const question8 = "8. ¿Qué utilización le das normalmente a tu servicio de internet? (opción múltiple)";

export const optionsSurvey8 = [
    { value: 1, label: 'Teletrabajo' },
    { value: 2, label: 'Educación virtual' },
    { value: 3, label: 'Descarga de archivos pesados' },
    { value: 4, label: 'Video llamadas' },
    { value: 5, label: 'Streaming' },
    { value: 6, label: 'Juegos en línea' },
    { value: 7, label: 'Otros' }
];

export const question9 = "9. ¿Por qué medio llegaste a conocer los servicios de Yiga5? (una opción)";

export const optionsSurvey9 = [
    { value: 1, label: 'Referido por un conocido' },
    { value: 2, label: 'Publicidad en redes sociales' },
    { value: 3, label: 'Publicidad impresa' },
    { value: 4, label: 'Publicidad por radio' },
    { value: 5, label: 'Publicidad en vía pública' },
    { value: 6, label: 'Publicidad por televisión' },
];

export const question10 = "10. ¿Qué proveedores de internet consideraste antes de elegir Yiga5?";

export const question11 = "11. ¿Qué medios prefieres utilizar para contactarnos? (dos opciones máximo)";

export const optionsSurvey11 = [
    { value: 1, label: 'Canal telefónico' },
    { value: 2, label: 'Centro de experiencia Mall El Fortín' },
    { value: 3, label: 'APP móvil' },
    { value: 4, label: 'Chat por whatsapp' },
    { value: 5, label: 'Chat por página web' },
    { value: 6, label: 'Redes sociales' },
    { value: 7, label: 'Correo electrónico' }
];

export const question12 = "12. ¿Qué redes sociales de Yiga5 sigues actualmente? (opción múltiple)";

export const optionsSurvey12 = [
    { value: 1, label: 'Instagram' },
    { value: 2, label: 'Facebook' },
    { value: 3, label: 'Tik Tok' },
    { value: 4, label: 'Youtube' },
    { value: 5, label: 'Linkedin' },
    { value: 6, label: 'Ninguna de las anteriores' }
];

export const question13 = "13. ¿Entre las alianzas comerciales que mantiene Yiga5 con otras marca, ¿qué beneficios has utilizado? (selección múltiple)";

export const optionsSurvey13 = [
    { value: 1, label: 'Servicio de telemedicina Yiga salud' },
    { value: 2, label: 'Programa de recompensas Yiga coins' },
    { value: 3, label: 'Servicio de antivirus Yiga defender' },
    { value: 4, label: '15% Descuento en gimnasios Taurus (aplica Guayaquil)' },
    { value: 5, label: '15% Descuento en McDonalds' },
    { value: 6, label: '15% descuento en farmacias Cruz Azul con receta Yigasalud' },
    { value: 7, label: '5% descuento en clases de natación en club Perla del Pacífico (aplica Guayaquil)' },
    { value: 8, label: '32.5% descuento en Join in English (aplica Guayaquil)' },
];

export const question14 = "14. ¿Cuáles son los principales atributos que buscas en un proveedor de servicios de internet al momento de contratarlo? (selecciona del 1 al 10 en orden de tu preferencia)";

export const optionsSurvey14 = [
    'Su reputación me da seguridad y confianza',
    'Tiene el mejor precio del mercado',
    'Posee una gran cobertura de red',
    'Garantiza un internet rápido',
    'Ofrece un excelente respaldo técnico',
    'Tiene el mejor servicio al cliente',
    'Es una empresa innovadora',
    'Cumple lo que promete a sus clientes',
    'Garantiza la conexión sin cortes ni caídas',
    'Ofrece beneficios adicionales en productos y servicios de otras marcas'
];

export const question15 = "15. ¿Cuáles consideras que son los principales atributos de Yiga5 en términos del servicio que le brinda hoy? (selecciona del 1 al 10 en orden de tu preferencia)";

export const optionsSurvey15 = [
    'Su reputación me da seguridad y confianza',
    'Tiene el mejor precio del mercado',
    'Posee una gran cobertura de red',
    'Garantiza un internet rápido',
    'Ofrece un excelente respaldo técnico',
    'Tiene el mejor servicio al cliente',
    'Es una empresa innovadora',
    'Cumple lo que promete a sus clientes',
    'Garantiza la conexión sin cortes ni caídas',
    'Ofrece beneficios adicionales en productos y servicios de otras marcas'
];

export const question16 = "16. En relación con otros proveedores de internet, ¿cómo consideras que Yiga5 compite en cada atributo? (selecciona una opción por cada atributo)";

export const optionsSurvey16 = [
    'Su reputación me da seguridad y confianza',
    'Tiene el mejor precio del mercado',
    'Posee una gran cobertura de red',
    'Garantiza un internet rápido',
    'Ofrece un excelente respaldo técnico',
    'Tiene el mejor servicio al cliente',
    'Es una empresa innovadora',
    'Cumple lo que promete a sus clientes',
    'Garantiza la conexión sin cortes ni caídas',
    'Ofrece beneficios adicionales en productos y servicios de otras marcas'
];

export const question17 = "17. ¿Consideras que es un precio justo lo que pagas actualmente por el plan de internet (velocidad + beneficios) que mantienes con Yiga5?";

export const optionsSurvey17 = [
    { value: 1, label: 'justo' },
    { value: 2, label: 'injusto' }
];

export const question18 = "18. Selecciona una opción del 1 al 5 que refleje tu nivel de satisfacción con nuestro servicio (si lo has utilizado). En esta escala, 1 significa 'Nada satisfecho' y 5 significa 'Muy satisfecho'. Si no has utilizado el servicio, selecciona 'N/A'. ¿Qué tan satisfecho estás con el servicio recibido?";

export const question19 = "19. ¿Qué probabilidad hay que recomiendes los servicios de Yiga5 a un familiar o amigo?";

export const question20 = "20. ¿Qué probabilidad hay que continues con el servicio de Yiga5 por mucho más tiempo?";

export const question21 = "21. ¿Qué probabilidad hay que pruebes nuevos productos o servicios de Yiga5?";

export const question22 = "22. ¿Qué probabilidad hay de cambiarte a otro proveedor si te ofrecieran un precio más bajo por un servicio similar?";

export const question23 = "23. En relación con tu proveedor de internet, ¿qué prefieres?";

export const optionsSurvey23 = [
    { value: 1, label: 'a. pagar un poco más por un servicio con beneficios exclusivos' },
    { value: 2, label: 'b. pagar un poco menos sin beneficios' }
];

export const question24 = "24. ¿Cuál sería el factor determinante para utilizar o mantener el servicio de televisión digital por Zapping que ofrecemos actualmente? (selección múltiple)";

export const optionsSurvey24 = [
    { value: 1, label: 'Precio más bajo' },
    { value: 2, label: 'Mayor variedad de canales' },
    { value: 3, label: 'Mejor calidad de streaming' },
    { value: 4, label: 'No estoy interesado' },
    { value: 5, label: 'Ya tengo Zapping' }
];

export const question25 = "25. ¿Qué tipo de promociones o beneficios te harían considerar continuar a largo plazo con Yiga5 frente a otras opciones del mercado? (selección múltiple)";

export const optionsSurvey25 = [
    { value: 1, label: 'Beneficios de entretenimiento' },
    { value: 2, label: 'Descuentos para tu mascota' },
    { value: 3, label: 'Descuentos en lugares de comida rápida' },
    { value: 4, label: 'Descuentos en gimnasios' },
    { value: 5, label: 'Participación en concursos por redes sociales' },
    { value: 6, label: 'Participación en campeonatos deportivos' },
    { value: 7, label: 'Participación en eventos empresariales' },
];

export const question26 = "26. ¿Consideras que Yiga5 comunica proactivamente sus beneficios y eventos relacionados para sus clientes? (una opción)";

export const optionsSurvey26 = [
    { value: 1, label: 'Siempre' },
    { value: 2, label: 'Frecuentemente' },
    { value: 3, label: 'No lo suficiente' },
    { value: 4, label: 'Definitivamente no' }
];

export const question27 = "27. Cuando piensas en Yiga5, ¿cuál sería el sentimiento que mejor describe tu percepción actual? (una opción)";

export const optionsSurvey27 = [
    { value: 1, label: 'Enfado' },
    { value: 2, label: 'Decepción' },
    { value: 3, label: 'Indiferencia' },
    { value: 4, label: 'Agradecimiento' },
    { value: 5, label: 'Afecto' }
];

export const question28 = "28. ¿Qué nos puedes sugerir para mejorar nuestro servicio y seguir innovando para ti?";