import React, { useState } from 'react';
import { Button, Container, FormControlLabel, Radio, Typography, Paper, Box } from '@mui/material';
import CedulaPage from './CedulaPage';
import Swal from "sweetalert2";
import { db, firebase } from '../../firebase';

export const EncuestaInstalacionSoei = () => {
  // Estados para almacenar las respuestas de la encuesta
  const [nivelFacilidad, setNivelFacilidad] = useState('');
  const [presentacionCordialidad, setPresentacionCordialidad] = useState('');
  const [asesoramientoUbicacion, setAsesoramientoUbicacion] = useState('');
  const [satisfaccionVisita, setSatisfaccionVisita] = useState('');
  const [probabilidadRecomendacion, setProbabilidadRecomendacion] = useState('');
  const [comentario, setComentario] = useState('');

  const [nombres, setNombres] = useState('');
  const [uid, setUid] = useState('');
  const [email, setEmail] = useState('');
  const [showLandingPage, setShowLandingPage] = useState(false);
  // Función para manejar el envío de la encuesta
  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí puedes enviar las respuestas a tu servidor o realizar cualquier otra acción
    console.log('Nivel de facilidad:', nivelFacilidad);
    console.log('Presentación y cordialidad:', presentacionCordialidad);
    console.log('Asesoramiento en ubicación:', asesoramientoUbicacion);
    console.log('Satisfacción con la visita:', satisfaccionVisita);
    console.log('Probabilidad de recomendación:', probabilidadRecomendacion);
    console.log('Comentario:', comentario);
    // Limpia los estados después de enviar la encuesta
    db.collection('encuesta_satisfaccion').doc().set({
      cedula: uid,
      nombre: nombres,
      email: email,
      nivelFacilidad: nivelFacilidad,
      presentacionCordialidad: presentacionCordialidad,
      asesoramientoUbicacion: asesoramientoUbicacion,
      satisfaccionVisita: satisfaccionVisita,
      probabilidadRecomendacion: probabilidadRecomendacion,
      comentario: comentario,
      medioPago: "web",
      origenEncuesta: "instalacion",
      created: firebase.firestore.FieldValue.serverTimestamp()
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: 'Gracias por compartir su experiencia',
        })
        console.log('Encuesta guardada correctamente en Firestore');
        // handleClose(); // Cerrar modal después de enviar la encuesta
      })
      .catch((error) => {
        console.error('Error al guardar la encuesta:', error);
      });
    setNivelFacilidad('');
    setPresentacionCordialidad('');
    setAsesoramientoUbicacion('');
    setSatisfaccionVisita('');
    setProbabilidadRecomendacion('');
    setComentario('');
  };

  return (
    <Container>
      {!showLandingPage ? ( // Mostrar la pantalla de cédula si showLandingPage es false
        <CedulaPage setShowLandingPage={setShowLandingPage} setUid={setUid} setNombres={setNombres} setEmail={setEmail} />
      ) : (
        <Container maxWidth="sm">
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '20px' }}>
              Encuesta de Servicio de Instalación
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box component="fieldset" marginBottom="20px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>1. ¿Qué nivel de esfuerzo le supuso concretar el proceso de instalación con Yiga5?:</Typography>
                {[...Array(5).keys()].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value.toString()}
                    checked={nivelFacilidad === value.toString()}
                    onChange={() => setNivelFacilidad(value.toString())}
                  />
                ))}
              </Box>
              <Box component="fieldset" marginBottom="20px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>2.¿Cómo califica la presentación y cordialidad por parte del técnico?:</Typography>
                {[...Array(5).keys()].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value.toString()}
                    checked={presentacionCordialidad === value.toString()}
                    onChange={() => setPresentacionCordialidad(value.toString())}
                  />
                ))}
              </Box>
              <Box component="fieldset" marginBottom="20px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>3. ¿Cómo califica el asesoramiento del técnico en la ubicación del equipo para asegurar una óptima cobertura del servicio?:</Typography>
                {[...Array(5).keys()].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value.toString()}
                    checked={asesoramientoUbicacion === value.toString()}
                    onChange={() => setAsesoramientoUbicacion(value.toString())}
                  />
                ))}
              </Box>
              <Box component="fieldset" marginBottom="20px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>4. ¿Cuál es su nivel de satisfacción general con la visita técnica?:</Typography>
                {[...Array(5).keys()].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value.toString()}
                    checked={satisfaccionVisita === value.toString()}
                    onChange={() => setSatisfaccionVisita(value.toString())}
                  />
                ))}
              </Box>
              <Box component="fieldset" marginBottom="50px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>5. ¿Qué tan probable es que recomiende los servicios de Yiga5 a un amigo o familiar?:</Typography>
                {[...Array(11).keys()].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value.toString()}
                    control={<Radio />}
                    label={value.toString()}
                    checked={probabilidadRecomendacion === value.toString()}
                    onChange={() => setProbabilidadRecomendacion(value.toString())}
                  />
                ))}
              </Box>
              <Box marginBottom="20px">
                <Typography component="legend" style={{ fontWeight: 'bold' }}>6. ¿Tiene algún comentario que quisiera compartir en base a sus respuestas?</Typography>
                <textarea value={comentario} onChange={(e) => setComentario(e.target.value)} rows="4" style={{
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                  resize: 'vertical'
                }} />
              </Box>
              <Button variant="contained" type="submit">Enviar encuesta</Button>
            </form>
          </Paper>
        </Container>

      )}
    </Container>
  );
};

